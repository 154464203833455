import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import { RichText } from 'prismic-reactjs';
import Img from 'gatsby-image';
import { getExcerpt } from '../core/utils';

export const pageQuery = graphql`
  query QueryInfos($uid: String!) {
    prismicPage(uid: { eq: $uid }) {
      data {
        title {
          text
        }
        texte {
          raw
          text
        }
        image_featured {
          url
          alt
          fluid(maxWidth: 1500) {
            ...GatsbyPrismicImageFluid
          }
        }
      }
    }
  }
`;

const Infos = ({ data }) => {
  const { title, texte, image_featured } = data.prismicPage.data;

  return (
    <div className="infos">
      <SEO
        pageTitle={title.text}
        pageDescription={getExcerpt(texte.text, 200).shortText}
        pageImage={image_featured.url}
        page={true}
        template="template-infos"
      />

      <div className="row">
        <div className="col-md-6 col-md-offset-6 col-xs-12">
          <div className="row">
            <div className="col-md-6 col-xs-12">
              {image_featured && <Img {...image_featured} />}
            </div>
            <div className="col-md-6 col-xs-12">
              {/* <div className="texte">{RichText.render(texte.raw)}</div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Infos;
